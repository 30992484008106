<template>
  <div>
    <template>
      <!-- Nombre del Grupo -->
      <b-form-group
        label="Nombre del Grupo"
        label-for="input-name"
        label-cols="0"
        label-cols-sm="4"
        label-cols-md="4"
        class="m-0 p-0"
      >
        <b-form-input
          id="input-name"
          aria-describedby="input-name-feedback"
          size="sm"
          v-model="$v.student_group.name.$model"
          :state="validateState('name')"
        ></b-form-input>
        <b-form-invalid-feedback id="input-name-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>
      <!-- Seleccionar Estudiantes -->
      <b-form-group
        label="Seleccionar Miembros del Grupo"
        label-for="input-name"
        label-cols="0"
        label-cols-sm="4"
        label-cols-md="4"
        class="m-0 p-0"
      >
        <b-input-group class="mb-1">
          <b-form-input
            v-model="input_search"
            type="search"
            size="sm"
            :placeholder="`Buscar...`"
          >
          </b-form-input>
          <b-input-group-append>
            <b-button
              :disabled="!input_search"
              @click="input_search = ''"
              size="sm"
              >Limpiar</b-button
            >
          </b-input-group-append>
        </b-input-group>
        <b-form-select
          id="input-students-group"
          aria-describedby="input-students-group-feedback"
          size="sm"
          v-model="$v.student_group.students.$model"
          :state="validateState('students')"
          :select-size="
            searchFullNameOptions.length > 18
              ? 18
              : searchFullNameOptions.length
          "
          @change="resetLeader"
          multiple
        >
          <option
            v-for="option in searchFullNameOptions"
            :key="option.id"
            :value="option.id"
            :disabled="isOptionDisabled.includes(option.id)"
            :class="{ 'disabled-option': isOptionDisabled.includes(option.id) }"
            :title="
              isOptionDisabled.includes(option.id)
                ? 'Este estudiante ya está en un Grupo'
                : ''
            "
          >
            {{ option.full_name }}
          </option>
        </b-form-select>
        <b-form-invalid-feedback id="input-students-group-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>
      <!-- Seleccionar Lider de Grupo -->
      <b-form-group
        label="Seleccionar Lider de Grupo"
        label-for="input-name"
        label-cols="0"
        label-cols-sm="4"
        label-cols-md="4"
        class="mt-2 ml-0 mr-0 p-0"
      >
        <v-select
          v-if="leaderOptions.length > 0"
          id="input-leader-group"
          aria-describedby="input-leader-group-feedback"
          size="sm"
          v-model="$v.student_group.leader.$model"
          :state="validateState('leader')"
          :reduce="(leaderOptions) => leaderOptions.id"
          :options="leaderOptions"
          placeholder="Seleccione Lider del Grupo"
          label="full_name"
          track-by="id"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontró resultados para <em>{{ search }}</em
              >.
            </template>
          </template>
        </v-select>
        <div class="mt-2" v-else>N/A</div>
        <b-form-invalid-feedback id="input-leader-group-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>
      <div class="container-btn-form">
        <b-button
          size="sm"
          class="mt-2 ml-1"
          variant="danger"
          @click="deleteGroup"
          >Eliminar</b-button
        >
        <b-button size="sm" class="mr-1 mt-2" @click="saveGroup"
          >Guardar</b-button
        >
      </div>
    </template>
  </div>
</template>


<script>
import { validationMixin } from "vuelidate";
import { generateUniqueId, toast } from "../../../../utils/utils";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  name: "GroupsForm",
  mixins: [validationMixin],
  components: {
    vSelect,
  },
  props: {
    Test: {
      type: Object,
      required: true,
    },
    StudentGroup: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          name: "",
          test: this.Test.id,
          leader: null,
          students: [],
        };
      },
    },
  },
  data() {
    return {
      student_group: { ...this.StudentGroup },
      input_search: "",
    };
  },
  validations() {
    return {
      student_group: {
        name: { required },
        test: {},
        leader: {},
        students: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      users: names.USERS,
      new_test_groups: names.NEW_TEST_GROUPS,
    }),
    newTestGroups() {
      return this.new_test_groups.filter((x) => x.test == this.Test.id);
    },
    filterUsersTest() {
      return this.users.filter((x) => this.Test.evaluatees.includes(x.id));
    },
    fullNameOptions() {
      return this.filterUsersTest.map((user) => ({
        ...user,
        full_name: `${user.user_data ? user.user_data.identifier + " " : ""}${
          user.first_name
        } ${user.last_name}`,
      }));
    },
    searchFullNameOptions: function () {
      return this.fullNameOptions
        .filter((item) => {
          return (
            this.$filtered(item.full_name, this.input_search) ||
            this.student_group.students.includes(item.id)
          );
        })
        .sort(function (a, b) {
          return a.full_name
            .toLowerCase()
            .localeCompare(b.full_name.toLowerCase());
        });
    },
    leaderOptions() {
      return this.fullNameOptions.filter((x) =>
        this.student_group.students.includes(x.id)
      );
    },
    isOptionDisabled() {
      return [
        ...new Set(
          this.newTestGroups
            .filter((x) => x.id != this.student_group.id)
            .map((x) => x.students)
            .flat()
        ),
      ];
    },
  },
  methods: {
    resetLeader() {
      if (!this.student_group.students.includes(this.student_group.leader))
        this.student_group.leader = null;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.student_group[name];
      return $dirty ? !$error : null;
    },
    saveGroup() {
      this.$v.student_group.$touch();
      if (this.$v.student_group.$anyError) return;
      if (isNaN(this.student_group.id)) this.createGroup();
      else this.upgradeGroup();
    },
    createGroup() {
      this.$store
        .dispatch(names.POST_NEW_TEST_GROUP, this.student_group)
        .then((response) => {
          this.student_group = response;
          this.$emit("created");
          toast("Se creó el Grupo");
        });
    },
    upgradeGroup() {
      this.$store
        .dispatch(names.UPDATE_NEW_TEST_GROUP, this.student_group)
        .then((response) => {
          this.student_group = response;
          this.$emit("updated");
          toast("Se editó el Grupo");
        });
    },
    deleteGroup() {
      if (!isNaN(this.student_group.id))
        this.$swal({
          title: `<p>¿Está seguro de que desea eliminar el <b>Grupo</b>?</p>`,
          text: "¡Esta acción no se podrá revertir!",
          type: "warning",
          showCancelButton: true,
        }).then((result) => {
          if (result.value) {
            this.$store.dispatch(
              names.DELETE_NEW_TEST_GROUP,
              this.student_group.id
            );
            toast("Se eliminó el Grupo");
            this.$emit("deleted");
          }
        });
    },
  },
  created() {},
};
</script>

<style scoped>
.container-btn-form {
  display: flex;
  justify-content: space-between;
}
.disabled-option {
  color: #999; /* Color de texto para las opciones deshabilitadas */
  text-decoration: line-through; /* Texto tachado para las opciones deshabilitadas */
}
</style>